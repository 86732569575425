html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #000712;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  font-family: 'TildaSansRegular';
  transition: 0.2s ease-in-out;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow: hidden;
}

h2 {
  margin: 0;
  font-family: 'TildaSansBold';
  font-size: 72px;
  line-height: 84px;
  text-align: center;

}

ul {
  padding: 0;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 48px;
}

li {
  cursor: pointer;
  list-style-type: none;
  margin: 10px 0;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1177px;
  background-image: url('./pages/MainPage/image/background-img.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

a {

  text-decoration: none;
  color: #FFFFFF;
  opacity: 90%;
  transition: color 0.3s ease;

  &:hover {
    color: #4670FE;
  }
}