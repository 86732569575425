.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; 
    border-bottom: 1px solid #FFFFFF21;

    &__content {
        height: 81px;
        background-color: #000712;
        padding: 0 200px;
        display: flex;
        justify-content: space-between;

        & div {
            display: flex;
            align-items: center; 
        }

        &-button {
            width: 292px;
            justify-content: flex-end;
        }

        &-logo {
            cursor: pointer;
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }

    }
}