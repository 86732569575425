.content {
    position: relative;
    width: 100%;

    &__window {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        margin-bottom: 98px;


        &-name {
            font-size: 12px;
            color: #FFFFFF;
            font-family: 'TildaSansRegular';
            outline: 1px solid #FFFFFF14;
            background: #FFFFFF0A;
            backdrop-filter: blur(32px);
            letter-spacing: 0.18em;
            line-height: 16px;
            padding: 8px 17.5px 8px 17.5px;
            margin-bottom: 24px;
            border-radius: 53px;
            text-transform: uppercase;

        }

        &-title {
            font-family: 'TildaSansBold';
            font-size: 38px;
            line-height: 45.6px;
            letter-spacing: -1px;
            text-align: center;
            max-width: 500px;
            z-index: 1;
        }

        &-desc {
            font-family: 'TildaSansRegular';
            opacity: 70%;
            margin-top: 32px;
            margin-bottom: 48px;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            width: 530px;
            letter-spacing: -0.5px;

        }

        &-button {
            width: 176px;

            &-desc {
                margin-top: 20px;
                font-size: 16px;
                opacity: 60%;
            }
        }


        &-img {
            margin-bottom: 24px;
            width: 1140px;
            height: 640px;
            background: rgba(88, 115, 146, 0.05);
            border: 1px solid rgba(237, 231, 225, 0.1);
            border-radius: 12px;
            backdrop-filter: blur(40px);
            mix-blend-mode: luminosity;


        }

        &-grid {
            width: 100%;
            display: flex;
            max-width: 1140px;

            &-item {
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(40px);
                display: flex;
                flex-direction: column;
                width: 364px;
                height: 188px;
                padding: 16px;
                overflow: hidden;
                border-radius: 12px;
                border: 1px solid rgba(237, 231, 225, 0.1);


                &-name {
                    font-family: 'TildaSansMedium';
                    color: #FFFFFF;
                    font-size: 18px;
                    line-height: 20px;
                    letter-spacing: -0.5px;
                    text-align: left;
                    margin: 12px 0 16px 0;
                }

                &-desc {
                    font-family: 'TildaSansRegular';
                    color: #FFFFFF99;
                    text-align: start;
                }
            }
        }


        &__second {
            display: flex;
            flex-direction: row;
            align-items: start;

            &-text {
                margin-top: 80px;
                margin-left: 4px;
                width: 450px;
                display: flex;
                flex-direction: column;
                text-align: start;

                &-name {
                    text-align: center;
                    text-transform: uppercase;
                }

                &-title {
                    text-align: start;
                }

                &-desc {
                    margin-top: 31px;
                    width: 450px;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.5px;
                    color: #FFFFFF99;
                }
            }

        }

        &__main {
            margin-top: 238px;
            font-family: 'TildaSansRegular';
            margin-bottom: 200px;

            &-name {
                margin-bottom: 34px;
            }

            &-title {
                font-size: 72px;
                line-height: 84px;
                max-width: 750px;

                & span {
                    color: #4670FF;
                }
            }

            &-desc {
                width: 500px;
                margin-bottom: 54px;
            }
        }
    }
}

.ellipse {
    width: 778px;
    height: 778px;
    background-color: rgba(41, 128, 214, 1);
    border-radius: 50%;
    filter: blur(1000px);
    position: absolute;
    top: 15%;
    left: 75%;
    z-index: -1;
}

.ellipse2 {
    width: 778px;
    height: 778px;
    background-color: rgba(41, 128, 214, 1);
    border-radius: 50%;
    filter: blur(1000px);
    position: absolute;
    top: 105%;
    left: 75%;
    z-index: -1;
}

.ellipse3 {
    width: 778px;
    height: 778px;
    background-color: rgba(41, 128, 214, 1);
    border-radius: 50%;
    filter: blur(1000px);
    position: absolute;
    top: 75%;
    left: -18%;
}



.grid-item-second {
    margin: 0 24px;
}

.norma-s1 {
    margin: 300px 0 150px 0;

    &-name {
        width: 142px;
    }
}

.omniclient {

    &-name {
        width: 157px;
    }

    &-button {
        width: 176px;
        margin-bottom: 20px;
    }
}

.clipboard-history {

    padding-top: 131px;
    margin-bottom: 145px;

    &-text {
        margin-top: 25.5px;
    }

    &-name {
        width: 180px;
    }

}

.applicability {
    margin-top: 180px;

    &-icon {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    &-text {
        margin-left: 129px;
        margin-top: 150px;
    }
}
.icon-1, .icon-2, .icon-3, .icon-4, .icon-5 {
    width: 150px;
    height: 170px;
    border-radius: 44px;
    border: 2px solid #4770FF;
    background: linear-gradient(180deg, #041B32 0%, rgba(2, 11, 20, 0.47) 100%);
    color: #CACACA;
    font-family: 'TildaSansSemiBold';
    font-size: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    box-shadow: 8px 8px 25px 0px #00000073;
    transition: 2s ease;
    animation: move 10s infinite alternate;

    & svg path {
        stroke: #346290;
        transition: 2s ease;
    }

    &:hover {
        box-shadow: 0px 0px 25px 0px #4770FF;

        & svg path {
            stroke: #4770ff;
        }
    }
}

.icon-1 {
    animation-delay: 0s;
}

.icon-2 {
    animation-delay: 0.2s;
}

.icon-3 {
    animation-delay: 0.4s;
}

.icon-4 {
    animation-delay: 0.6s;
}

.icon-5 {
    animation-delay: 0.8s;
}


@keyframes move {
    0% {
        transform: translateX(0) translateY(0);
    }
    25% {
        transform: translateX(5px) translateY(5px);
    }
    50% {
        transform: translateX(-5px) translateY(-5px);
    }
    75% {
        transform: translateX(5px) translateY(-5px);
    }
    100% {
        transform: translateX(-5px) translateY(5px);
    }
}




.icon-1 {
    margin-left: 399px;
}

.icon-2 {
    margin-top: -53px;
}

.icon-3 {
    margin-top: -82px;
    margin-left: 229px;
}

.icon-4 {
    margin-top: -25px;
    margin-left: 429px;
}
.icon-5 {
    margin-top: -92px;
    margin-left: 52px;
}





.policamera {
    padding-top: 144px;
}

.faqs {
    width: 100%;
    display: flex;
    justify-content: center;

    &-content {
        width: 700px;
        display: flex;
        text-align: left;
        align-items: start;
    }

    &-title {
        width: 700px;
        max-width: 700px;
        display: flex;
        text-align: left;
        font-size: 36px;
    }

    &-desc {
        width: 700px;
        margin: 24px 0 0 0;
        font-size: 22px;
        line-height: 28px;
    }

    &-list {
        margin-top: 17px;
        width: 700px;

        &-item {
            padding-top: 32px;
            cursor: pointer;
            height: 58px;
            border-top: 1px solid #FFFFFF1A;
            display: flex;
            justify-content: space-between;
            color: #FFFFFF;
            font-size: 18px;
            font-family: 'TildaSansSemiBold';
            line-height: 24px;

            &:first-child {
                border: none;
            }

            &:last-child {
                border-bottom: 1px solid #FFFFFF1A;
            }
        }
    }

    .faqs-answer {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        transform: translateY(-30px);
        transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease, max-height 0.3s ease, padding-bottom 0.3s ease;
    }

    .faqs-list-item.open+.faqs-answer {
        max-height: 500px;
        opacity: 1;
        visibility: visible;
        padding-bottom: 20px;
        transform: translateY(0);
    }


    .SVGArrow {
        transition: transform 0.2s ease-in-out;
    }

    .faqs-list-item.open .SVGArrow {
        transform: rotate(180deg);
    }
}

.canopus-switcher {
    width: 210px;
    height: 40px;
    background-color: #FFFFFF1A;
    border-radius: 41px;
    display: flex;
    align-items: center;
    margin-bottom: 81px;
    position: relative;
    cursor: pointer;
}

.canopus-switcher-standalone,
.canopus-switcher-virtual {
    box-sizing: border-box;
    user-select: none;
    height: 32px;
    width: 107px;
    margin: 4px;
    border-radius: 48px;
    padding: 8px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    font-weight: 500;
    cursor: pointer;
}

.canopus-switcher-toggle-background {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 107px;
    height: 32px;
    background-color: #FFFFFF1A;
    border-radius: 48px;
    transition: 0.3s ease-in-out;
    z-index: -1;
}

.canopus {

    &-title {
        max-width: 700px;
    }

    &-products {
        display: flex;
        text-align: left;
        justify-content: space-between;

        &-panel {
            padding: 36px;
            border-radius: 24px;
            background-color: #FFFFFF0D;
            border: 2px solid #346290;
            box-shadow: 10px 10px 37.7px 0px #000000;

            & .hr {
                height: 1px;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
                position: relative;
                top: 10%
            }

            &-name {
                font-family: "TildaSansSemiBold";
                width: 338px;
                font-size: 28px;
                line-height: 28px;
                background: linear-gradient(90.88deg, #7892EC 0.37%, #6129D2 89.21%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

            }

            &-desc {
                margin-top: 10px;
                color: #FFFFFF99;
                font-family: "TildaSansRegular";
                font-size: 18px;
                line-height: 20px;
            }

            &-version {
                margin: 40px 0 45px 0;
                display: flex;
                text-align: center;
                justify-content: center;

                & span {
                    width: 144px;
                    height: 30px;
                    background-color: #FFFFFF1A;
                    backdrop-filter: blur(40.5px);
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFFFFF;
                }

            }

            &-list {
                margin-bottom: 79px;

                &-desc {
                    font-family: 'TildaSansMedium';
                    font-size: 17px;
                    line-height: 20px;
                    margin-bottom: 20px;
                }

                &-item {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;

                    & span {
                        margin-left: 11.67px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            & button {
                height: 40px;
                width: 338px;
            }
        }
    }

    &-details {
        margin-top: 80px;
        margin-bottom: 73px;
        padding: 0 15px;
        height: 40px;
        background-color: #FFFFFF1A;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 41px;
        color: #FFFFFF99;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.05px;
        font-family: 'TildaSansMedium';


        & a {
            cursor: pointer;
            height: 40px;
            margin: 0 0 0 15px;
            font-family: 'TildaSansBold';
            color: #FFFFFF;
            opacity: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                margin-left: 15px;

                path {
                    transition: stroke 0.3s ease;
                }

            }

            &:hover {
                color: #4670FE;

                & svg path {
                    stroke: #4670FE;
                }
            }
        }
    }
}

.second-panel {
    margin: 0 50px;
    border: 3px solid #2980D6;

}

.second-list {
    margin-bottom: 34px;
}

.canopus {

    &-server {
        margin: 200px 0 225px 0;

        &-text {
            width: 500px;
        }

        &-name {
            width: 235px;
        }

        &-desc {
            width: 500px;
        }

    }
}