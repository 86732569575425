@font-face {
	font-family: 'TildaSansLight';
	src: url('./TildaSans/TildaSans-Light.eot');
	src:
	     url('./TildaSans/TildaSans-Light.eot?#iefix') format('embedded-opentype'),
	     url('./TildaSans/TildaSans-Light.woff2') format('woff2'),
	     url('./TildaSans/TildaSans-Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSansRegular';
	src: url('./TildaSans/TildaSans-Regular.eot');
	src:
	     url('./TildaSans/TildaSans-Regular.eot?#iefix') format('embedded-opentype'),
	     url('./TildaSans/TildaSans-Regular.woff2') format('woff2'),
	     url('./TildaSans/TildaSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSansMedium';
	src: url('./TildaSans/TildaSans-Medium.eot');
	src:
	     url('./TildaSans/TildaSans-Medium.eot?#iefix') format('embedded-opentype'),
	     url('./TildaSans/TildaSans-Medium.woff2') format('woff2'),
	     url('./TildaSans/TildaSans-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSansBold';
	src: url('./TildaSans/TildaSans-Bold.eot');
	src:
	     url('./TildaSans/TildaSans-Bold.eot?#iefix') format('embedded-opentype'),
	     url('./TildaSans/TildaSans-Bold.woff2') format('woff2'),
	     url('./TildaSans/TildaSans-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSansSemibold';
	src: url('./TildaSans/TildaSans-Semibold.eot');
	src:
	     url('./TildaSans/TildaSans-Semibold.eot?#iefix') format('embedded-opentype'),
	     url('./TildaSans/TildaSans-Semibold.woff2') format('woff2'),
	     url('./TildaSans/TildaSans-Semibold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSansExtraBold';
	src: url('./TildaSans/TildaSans-ExtraBold.eot');
	src:
	     url('./TildaSans/TildaSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
	     url('./TildaSans/TildaSans-ExtraBold.woff2') format('woff2'),
	     url('./TildaSans/TildaSans-ExtraBold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSansBlack';
	src: url('./TildaSans/TildaSans-Black.eot');
	src:
	     url('./TildaSans/TildaSans-Black.eot?#iefix') format('embedded-opentype'),
	     url('./TildaSans/TildaSans-Black.woff2') format('woff2'),
	     url('./TildaSans/TildaSans-Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}