.footer {
    width: 100%;
    height: 682px;
    border-top: 1px solid #FFFFFF21;

    &__content {
        padding: 108px 200px 86px 200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-navbar {
            display: flex;

            &-name {
                user-select: none;
                font-family: 'TildaSansMedium';
                font-size: 18px;
                color: #7F8CA6;
            }

        }


        &-contacts {
            display: flex;
            flex-direction: column;



            &-phone {
                margin-top: 24px;
                margin-bottom: 4px;
            }

            &-mail {
                margin-bottom: 24px;
            }

            &-socials {
                width: 56px;
                display: flex;
                justify-content: space-between;

                & svg {
                    cursor: pointer;

                    &:hover {
                        & path {
                            stroke: #4670FE;
                        }
                    }
                }
            }
        }

        &-adress {
            display: flex;
            flex-direction: column;
            align-items: end;

            & button {
                margin: 11px 0 11px 0;
            }

            & div {
                opacity: 80%;
                display: flex;
                flex-direction: column;
                align-items: end;

                & span:first-child {
                    margin-top: 16px;
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 0 200px;

        & span,
        a {
            opacity: 60%;
            font-size: 14px;
        }
    }
}