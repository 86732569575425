.title {
    display: inline-block;
    max-width: 1000px;
    user-select: none;
    height: 250px;
  }
  
  
  .cursor {
    color: #4670ff;
    display: inline-block;
    animation: blink 0.8s step-end infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  