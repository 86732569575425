.navbar {
    flex-grow: 1;
    display: flex;

    &__list {
        list-style: none;
        display: flex;
        gap: 20px;
        height: 40px;
        margin: 0;
        padding: 0;
        position: relative;
        align-items: center;
    }

    &__item {
        position: relative;

        span {
            background: none;
            border: none;
            color: #FFFFFF;
            font-size: 16px;
            cursor: pointer;
            padding: 10px;
            transition: color 0.3s;
            display: flex;
            align-items: center;
            gap: 10px;

            & svg path {

                fill:#FFFFFF;
                transition: fill 0.3s ease;
            }

            &:hover {
                color: #4670FE;

                & svg path {
                    fill:#4670FE;
                }
            }
        }

        .dropdown-menu {
            position: absolute;
            top: 80%;
            right: 0;
            background: linear-gradient(180deg, #041B32 0%, rgba(2, 11, 20) 100%);
            border: 1px solid #346290;
            border-radius: 6px;
            padding: 18px;
            display: flex;
            gap: 20px;
            flex-direction: column;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            box-shadow: 2px 4px 25px 0px #35353580;
            z-index: 999;
            transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

            li {
                margin: 0;

                a {
                    font-family: 'TildaSansMedium';
                    font-size: 16px;
                    line-height: 19.2px;
                    color: #FFFFFF;
                    text-decoration: none;
                    white-space: nowrap;

                    &:hover {
                        color: #4670FE;
                    }
                }
            }
        }

        &:hover .dropdown-menu,
        &.open .dropdown-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}